import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { Box } from 'src/components/designsystem'
import { LoadingState } from 'src/components/resource'
import { useAuth } from 'src/auth'
import { useConfig } from 'src/data/config'
import { getUserType, resetUserId, setCustomDimensions, setUserId } from 'src/utils/analytics'
import { flagr, getUserRedirectUri, logger, updateAllAuthTokens } from 'src/utils'
import { setDatadogUser, removeDatadogUser } from 'src/utils/datadog/rum'
import { removeFailedLoginMarker } from 'src/data/failed-login'

/* istanbul ignore next */
export default function Check(_props) {
  const mountedRef = useRef(false)
  const { replace, query } = useRouter()
  const config = useConfig()
  const { loading, authenticated, slug, user } = useAuth()
  const isStaffFlagEnabled = flagr.useGlobalFlag(flagr.FLAG_KEYS.WEBAPP_STAFF_ROUTES)
  const landingFeature = config.config.landing_feature
  const nextPath = query?.next as string

  useEffect(() => {
    if (loading) return console.log('[auth/check] - skipping effect, auth is loading')
    if (mountedRef.current) return console.log('[auth/check] - skipping effect, already mounted')

    mountedRef.current = true

    if (authenticated) {
      // TODO: If `user` is null here, we should really try to re-fetch `auth/me` once
      setCustomDimensions({ isLoggedIn: true, userType: getUserType(user) })
      setUserId(user?.id.toString())
      setDatadogUser({ slug, user })

      const redirectPath = getUserRedirectUri({
        slug,
        user,
        nextPath,
        isStaffFlagEnabled,
        landingFeature,
      })
      logger.info({ message: '[auth/check] redirecting to path', context: { redirectPath } })
      replace(redirectPath)
    } else {
      setTimeout(() => {
        setCustomDimensions({ isLoggedIn: false, userType: null })
        resetUserId()
        removeDatadogUser()
        updateAllAuthTokens(null)

        replace(['', slug, 'welcome'].join('/'))
      })
    }
  }, [loading, authenticated, slug, user, isStaffFlagEnabled, landingFeature, nextPath, replace])

  return (
    <Box mt={16}>
      <LoadingState />
    </Box>
  )
}

Check.requireAuth = false

// This situation is most-likely triggered when more than one un-authenticated tab is open, then a log-in is performed within one of those tabs
export function useAuthCheck() {
  const { slug, loading, authenticated } = useAuth()

  useEffect(() => {
    if (loading) return

    if (authenticated) {
      window.location.assign(['', slug, 'auth', 'check'].join('/'))
      return
    }

    removeFailedLoginMarker()
  }, [slug, loading, authenticated])
}
