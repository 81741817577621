import { useState } from 'react'
import { Box, Button, Heading, Img, Text, VStack } from 'src/components/designsystem'
import { useNavigation } from 'src/data'
import { useAuth } from 'src/auth'
import { useAuthCheck } from 'src/pages/[company]/auth/check'

export default function Welcome() {
  const { handleNextAuthSignIn } = useAuth()

  const [showSpinner, setShowSpinner] = useState(false)

  useAuthCheck()

  useNavigation({ pageTitle: 'Welcome', activeItem: 'Welcome', analyticsTitle: 'Welcome' })

  return (
    <>
      <VStack
        alignItems="center"
        alignSelf="center"
        height="100%"
        width={['90%', null, null, '80%', '50%', '40%']}
        spacing={6}
        position="absolute"
      >
        <Box bg="#ffffff" boxShadow="xs" p={10} margin="auto" textAlign="center">
          <Img
            src="/img/illustrations/welcome.png"
            alt="Welcome"
            margin="auto"
            width={['260px', null, '450px']}
          />

          <Heading fontSize="2rem" px={[2, null, 0]} textAlign="center" fontWeight="700">
            Come on in!
          </Heading>

          <Text px={[2, null, 0]} py={4} textAlign="center">
            Feel free to explore and log in to experience all of the features!
          </Text>

          <Box mt={2}>
            <Button
              variant="primary"
              size="md"
              onClick={() => {
                setShowSpinner(true)
                handleNextAuthSignIn()
              }}
              isLoading={showSpinner}
            >
              Sign in
            </Button>
          </Box>
        </Box>
      </VStack>
    </>
  )
}

Welcome.requireAuth = false
